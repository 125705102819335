import BpkProgress from '@skyscanner/backpack-web/bpk-component-progress';
import BpkRating, {
  RATING_SIZES,
} from '@skyscanner/backpack-web/bpk-component-rating';
import BpkThemeProvider from '@skyscanner/backpack-web/bpk-theming';
import { marcommsDarkSky } from '@skyscanner/bpk-foundations-web/tokens/base.es6';

import STYLES from './Rating.module.scss';

const MAX_VALUE = 5;
const MIN_VALUE = 0;

export default ({
  ariaLabel,
  label,
  rating,
}: {
  rating: number;
  label: string;
  ariaLabel: string;
}) => (
  <div className={STYLES.Rating}>
    <div className={STYLES.Rating__description}>
      <div className={STYLES.Rating__label}>{label}</div>
      <div className={STYLES.Rating__middle} />
      <div className={STYLES.Rating__rating}>
        {rating > 1 && (
          <BpkRating
            ariaLabel=""
            title=""
            size={RATING_SIZES.base}
            value={rating.toFixed(1)}
          />
        )}
      </div>
    </div>
    <div className={STYLES.Rating__progress}>
      <BpkThemeProvider
        theme={{ progressBarFillColor: marcommsDarkSky }}
        themeAttributes={['progressBarFillColor']}
      >
        <BpkProgress
          min={MIN_VALUE}
          max={MAX_VALUE}
          small
          value={rating > 1 ? rating : 0}
          aria-label={ariaLabel}
        />
      </BpkThemeProvider>
    </div>
  </div>
);
