import Rating from '../Rating/Rating';

import type { RatingInfo } from 'common-types/types/ProviderRating';

import STYLES from './DetailRating.module.scss';

type DetailRatingProps = {
  ratingInfo: RatingInfo;
};

export default (props: DetailRatingProps) => {
  const { ratingInfo } = props;

  if (!ratingInfo) {
    return null;
  }

  return (
    <div className={STYLES.DetailRating}>
      {ratingInfo.items.map((item) => (
        <Rating
          key={item.name}
          rating={item.rating}
          label={item.name}
          ariaLabel={item.ariaLabel || ''}
        />
      ))}
    </div>
  );
};
