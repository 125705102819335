import { useEffect } from 'react';

import loadable from '@loadable/component';

import { BpkAccordion } from '@skyscanner/backpack-web/bpk-component-accordion';
import BpkSectionHeader from '@skyscanner/backpack-web/bpk-component-section-header';

import { ACTION_TYPE, LOAD_STATUS, COMPONENT_NAME } from '../../../constants';
import logMiniEventHelper from '../../../mini-event/logMiniEventHelper';
import withErrorBoundary from '../../ErrorBoundary';
import { useMBDContext, buildMBDParam } from '../../common/MBD/useMBDContext';
import QuestionAndAnswer from '../QuestionAndAnswer';

import type { FaqsProps } from 'common-types/types/FaqsProps';

import STYLES from './FAQs.module.scss';

const IntersectionObserverWrapper = loadable(
  () => import('../../IntersectionObserverWrapper/IntersectionObserverWrapper'),
);

const FAQs = ({
  ldJsonData,
  postcardImageData,
  questions,
  title,
  trackingContext,
}: FaqsProps) => {
  useEffect(() => {
    logMiniEventHelper({
      action_type: ACTION_TYPE.COMPONENT_LOADED,
      component_name: COMPONENT_NAME.FAQS,
      load_status: questions?.length ? LOAD_STATUS.LOADED : LOAD_STATUS.INVALID,
    });
  }, [questions]);
  const { rawComponentName, vertical } = useMBDContext();

  if (!questions?.length) {
    return null;
  }

  const items = questions.map((q) => (
    <QuestionAndAnswer {...q} {...trackingContext} key={q.id} />
  ));
  return (
    <>
      <div
        className={STYLES.FAQs}
        id="faqs"
        data-tracking-element-id={rawComponentName}
        data-tracking-common-params={buildMBDParam(rawComponentName, vertical)}
      >
        <div className={STYLES.FAQs__header}>
          <BpkSectionHeader title={title} />
        </div>
        <BpkAccordion className={STYLES.FAQs__questions}>{items}</BpkAccordion>
        {ldJsonData && (
          <script
            type="application/ld+json"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: ldJsonData }}
          />
        )}
      </div>
      {postcardImageData ? (
        <div className={STYLES.FAQs__image}>
          <img
            id="postcardImage"
            alt={postcardImageData.name}
            src={postcardImageData.imageUrl}
          />
        </div>
      ) : null}
    </>
  );
};

const FAQsWrapper = (props: FaqsProps) => (
  <IntersectionObserverWrapper
    onElementSeen={() =>
      logMiniEventHelper({
        action_type: ACTION_TYPE.COMPONENT_VISIBLE,
        component_name: COMPONENT_NAME.FAQS,
      })
    }
  >
    <FAQs {...props} />
  </IntersectionObserverWrapper>
);
const FAQsWithErrorBoundary = withErrorBoundary(FAQsWrapper, 'faqs-component');
export default FAQsWithErrorBoundary;
