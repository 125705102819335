import type { ReactNode } from 'react';
import { createContext, useContext, useMemo } from 'react';

import type { Common as MBDCommon } from 'trackolding/lib/modern';

interface MBDContextType {
  rawComponentName: string;
  vertical: string;
}

interface MBDContextProviderProps {
  children: ReactNode;
  rawComponentName: string;
  vertical: string;
}

const MBDContext = createContext<MBDContextType>({
  rawComponentName: '',
  vertical: '',
});

// prase component name, for example: INFO_SNIPPET => seo_vertical_cross_links
const parseComponentName = (rawComponentName: string) =>
  `seo_${rawComponentName.replaceAll('-', '_').toLowerCase()}`;

// create context for each component
export function MBDContextProvider({
  children,
  rawComponentName,
  vertical,
}: MBDContextProviderProps) {
  const mbdProps = useMemo(
    () => ({
      rawComponentName: parseComponentName(rawComponentName),
      vertical,
    }),
    [rawComponentName, vertical],
  );

  return <MBDContext.Provider value={mbdProps}>{children}</MBDContext.Provider>;
}

export function useMBDContext(): MBDContextType {
  const context = useContext(MBDContext);
  return context;
}

export function buildMBDParam(
  rawComponentName: string | undefined,
  vertical: string | undefined,
  param: MBDCommon['string_properties'] = {},
) {
  if (!rawComponentName) return '';
  const mbdParam: MBDCommon = {
    component_name: rawComponentName,
    string_properties: vertical
      ? {
          ...param,
          vertical,
        }
      : param,
  };
  return JSON.stringify(mbdParam);
}
