import BpkImage, {
  BORDER_RADIUS_STYLES,
} from '@skyscanner/backpack-web/bpk-component-image';

import type { ProviderInfo } from 'common-types/types/ProviderRating';

import STYLES from './ProviderLogo.module.scss';

type ProviderLogoProps = {
  provider: ProviderInfo;
};

const ProviderLogo = (props: ProviderLogoProps) => (
  <div className={STYLES.ProviderLogo}>
    <BpkImage
      className={STYLES.ProviderLogo__image}
      src={props.provider.src}
      altText={props.provider.providerName}
      aspectRatio={1}
      borderRadiusStyle={BORDER_RADIUS_STYLES.sm}
    />
  </div>
);

export default ProviderLogo;
